import React, {Component} from 'react';
import { Nav, Navbar, NavbarBrand, NavbarToggler, Collapse, NavItem, Jumbotron } from 'reactstrap';
import { NavLink, withRouter } from 'react-router-dom';
import base_app from '../firebase';
import '../App.css';


class Header extends Component {
    constructor(props) {
        super(props);
    
        this.toggleNav = this.toggleNav.bind(this);
        this.state = {
          isNavOpen: false,
          name: null
        };
      }

      toggleNav() {
        this.setState({
          isNavOpen: !this.state.isNavOpen
        });
      }

    componentDidMount = () =>{
        console.log(base_app.auth())

        

        if(base_app.auth().currentUser){
            this.setState({name:base_app.auth().currentUser.displayName})
            console.log(base_app.auth().currentUser.displayName)

        }

        
    }

    render() {
        return(
            <div> 
                {!!!this.props.location.pathname.toString().includes('/sign') ?
                <Navbar dark expand="md">
                    <div className="container">
                    <NavbarToggler onClick={this.toggleNav}/>
                        <NavbarBrand><img src='assets/images/Logo.png' height="60px" alt='Thuma uThenjiwe' /> </NavbarBrand>
                    <Collapse isOpen={this.state.isNavOpen} navbar>
                    <Nav navbar className="ml-auto">
                        <NavItem>
                            <NavLink className="nav-link" style={navStyle} to='/home'><span className="fa fa-home fa-lg"></span>&nbsp;Home</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className="nav-link" style={navStyle} to='/about'><span className="fa fa-info fa-lg"></span>&nbsp;&nbsp;About Us</NavLink>
                        </NavItem>
                        

                        
                        <NavItem>
                            <NavLink className="nav-link" style={navStyle} to='/cart'> <span className="fa fa-shopping-cart fa-lg"></span>&nbsp; View Cart</NavLink>
                        </NavItem> 
                        {/* < NavItem>
                            <NavLink className="nav-link" style={navStyle} to='/profile'><span className="fa fa-user fa-lg"></span>&nbsp;My Profile</NavLink>
                        </NavItem> */}
                        <NavItem>
                            <NavLink className="nav-link" onClick={() => base_app.auth().signOut()} style={navStyle} to='/signin'><span className="fa fa-sign-out fa-lg"></span>&nbsp;Sign Out</NavLink>
                        </NavItem>
                    </Nav> 
                    </Collapse>
                             
                    </div>
                </Navbar>
                : <Navbar dark expand="md">
                <div className="container">
                <NavbarBrand><img src='assets/images/Logo.png' height="60px" alt='Thuma uThenjiwe' /> </NavbarBrand>
                </div>
        </Navbar>}
                <Jumbotron>
                    <div className="container">
                        <div className="row row-header">
                            <p> We connect you to wholesalers and clothing factory shops from the big cities. Start shopping now!</p>
                        </div>
                    </div>
                </Jumbotron>
            </div>
        );
    }
}


export default withRouter(Header);

const navStyle = 
    {color:'#33ACFF'};
