import React, { useCallback , useState, useEffect} from "react";
import base_app from '../firebase';

const Profile = ({ history }) => {
    const [state, setState] = useState({ firebase: false, name:'', surname:'', cell:'', email:''});

    useEffect(() => {
        if (state.firebase === false){
            setState({firebase: true});
            if(base_app.auth().currentUser){
                // To get the name
                console.log(base_app.auth().currentUser)
                // base_app.database().ref('/users/' + base_app.auth().currentUser.uid).once('value').then(function(snapshot) {
                //     setState({name:snapshot.val().name, surname: snapshot.val().surname, email:snapshot.val().email, cellno:snapshot.val().cell_no});
                // });
 
            }
        }
      
    
    }, [state.firebase, history]);


    const updateUserData = (name, surname, email ,cell, userId) =>{
        base_app.database().ref('users/' + userId).update({
            name: name,
            surname:surname,
            email: email,
            cell_no : cell
          });

        base_app.auth().currentUser.updateEmail(email).then(function() { });
        base_app.auth().currentUser.updateProfile({displayName: name}).then(function() {});

        if (cell.toString().startsWith("0") ){
            let number = "+27"+cell.toString().slice(1);
            if ( number !== base_app.auth().currentUser.phoneNumber){
                base_app.auth().currentUser.updatePhoneNumber(number).then(function() { });
            }
        }
    }

    const handleSignUp = useCallback(async event => {
        event.preventDefault();
        const { name, surname, email ,cell } = event.target.elements;
        updateUserData(name.value, surname.value, email.value, cell.value, base_app.auth().currentUser.uid.toString());
        history.push("/");
    }, [history]);

   

    return (
        <div className ="auth-wrapper">
           
           <form  onSubmit = {handleSignUp}>
           <h3>Edit Profile</h3>

           <div className="form-group">
               <label>Name*</label>
               <input type="text" name="name" value ={state.name} className="form-control"  required/>
           </div>

           <div className="form-group">
               <label>Surname*</label>
               <input type="text" name="surname"  value ={state.surname} className="form-control"  required/>
           </div>

           <div className="form-group">
               <label>Cell no.*</label>
               <input type="text"name="cell"  value ={state.cellno} className="form-control" />
           </div>

           <div className="form-group">
               <label>Email address</label>
               <input type="email"name="email" value ={state.email} className="form-control" />
           </div>


           <button type="submit" className="btn btn-primary btn-block">Save</button>
           
       </form>
       </div>
        
    );
};

export default Profile;