import React, { useEffect, useState } from 'react';
import base_app from './firebase';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser ]= useState(null);


useEffect(() => {
    base_app.auth().onAuthStateChanged(setCurrentUser);
}, []);



return(
    <AuthContext.Provider
        value = {{
            currentUser
        }}>

        {children}
    </AuthContext.Provider>

)};