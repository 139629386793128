import React, { Component } from 'react'; 
import { Card, CardTitle, CardBody, CardText, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';
;

class About extends Component{
    render() {
        return (
            <div className="container">
                 <div className="row">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/home">Back to Home</Link></BreadcrumbItem>
                        <BreadcrumbItem active>About Us</BreadcrumbItem>
                    </Breadcrumb> 
                   
                </div>
                <div className="row">
                    <div  className="col-12 col-md-5 m-1" style={body}>

                        <Card>
                            <CardBody>
                                <CardTitle>About Us</CardTitle>
                                <CardText style= {text}>
                            
                                Our service connects  aspiring entrepreneurs that are looking for a side or main hustle, 
                                to products, factory shops and wholesalers selling stock for distribution or to be-resold.
                                <br/>
                                <br/>
                                You can conveniently shop in our store from wherever you are. The process is simple. You 
                                select the stock that you want, place your order, pay. We source the items from the suppliers 
                                and then we deliver the items to you. We deliver to anywhere in South Africa! 
                                <br/>
                                <br/>
                                If you cannot find something that you are looking for in our store, please send us a direct 
                                message and we will do our best to locate it for you.
                                </CardText>     
                                
                                <br/>
                                <hr></hr> 
                                <br/>

                                <CardTitle><u>How it works for bulk-buyers / new distributors:</u></CardTitle>
                                <CardText style= {text}>
                                    <ol>
                                        <li>Once you have logged-in to our application you can browse the available stock. We have stock from factory shops in Cape Town and Durban.</li>
                                        <li>If you find something that you want, you can order and pay immediately. We charge service fee of 10%  and a delivery fee.</li>
                                        <li>We accept credit, cheque card payments, or directly into our bank account (use your name and surname as reference). <br/>*Please note orders done by Bank deposit and EFT are subject to 7 days waiting period.</li>
                                        <li>Once an order has been placed from our application, we will source the goods or products and deliver to you within 2 – 5 days.</li>
                                    </ol>
                                </CardText>  
                                
                                <br/>
                                <hr></hr>     
                                <br/>
                                
                                <CardTitle>Return Policy:</CardTitle>
                                <CardText style= {text}>
                            
                                    <ol>
                                        <li>Goods can be returned and exchanged.</li>
                                        <li>No return of cash on bulk-sales.</li>
                                        <li>Exchanges need to be done within 3 – 7days, after receipt of goods.</li>
                                    </ol>
                                </CardText>    

                            </CardBody> 
                        </Card>
                    </div>            
                </div>
        </div>
        );
    }
   
}

export default About;

const body = {
    display: 'contents',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '.75rem'

}

const text = {
    fontSize: '.75rem',
    lineHeight: 1.9
    
}

