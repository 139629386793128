import React, { useCallback , useState, useEffect} from "react";
import base_app from '../firebase';

const CreateProfile = ({ history }) => {
    const [state, setState] = useState({ firebase: false, name:'', surname:'', cell:'', email:''});

    useEffect(() => {
        if(base_app.auth().currentUser){
                // To get the name
                // base_app.database().ref('/users/' + base_app.auth().currentUser.uid).once('value').then(function(snapshot) {
                //     setState({name:snapshot.val().name, surname: snapshot.val().surname, email:snapshot.val().email, cellno:snapshot.val().cell_no});
                // });
                setState({ email: base_app.auth().currentUser.email, cellno:base_app.auth().currentUser.phoneNumber});

                console.log(base_app.auth().currentUser);
          
        }
    
    }, [state.firebase, history]);

    const addUserData = (name, surname, email, userId) =>{

        // add check for number format 

        if (email.value != null || email.value > 0){
            base_app.database().ref('users/' + userId).set({
                name: name,
                surname:surname,
                email: email,
                cell_no : base_app.auth().currentUser.phoneNumber
              });

              base_app.auth().currentUser.updateEmail(email).then(function() { });
              base_app.auth().currentUser.updateProfile({displayName: name}).then(function() {});
      
    
        } else {

            base_app.database().ref('users/' + userId).set({
                name: name,
                surname:surname,
                email: "none",
                cell_no : base_app.auth().currentUser.phoneNumber
              });

              base_app.auth().currentUser.updateProfile({displayName: name}).then(function() {});
      
    
        }
        

     
        // if (cell.toString().startsWith("0") ){
        //     let number = "+27"+cell.toString().slice(1);
        //     if ( number !== base_app.auth().currentUser.phoneNumber){
        //         base_app.auth().currentUser.updatePhoneNumber(number).then(function() { });
        //     }
        // }


    }

  

    const handleSignUp = useCallback(async event => {
        event.preventDefault();
        const { name, surname, email } = event.target.elements;

        addUserData(name.value, surname.value, email.value, base_app.auth().currentUser.uid.toString());
        history.push("/")
    }, [history]);

  

    return (
        <div className ="auth-wrapper">
            <form  onSubmit = {handleSignUp}>
                <h3>Sign-up</h3>

                <div className="form-group">
                    <label>Name*</label>
                    <input type="text" name="name" defaultValue ={state.name} className="form-control"  />
                </div>

                <div className="form-group">
                    <label>Surname*</label>
                    <input type="text" name="surname"  defaultValue ={state.surname} className="form-control"  />
                </div>

                {/* <div className="form-group">
                    <label>Cell no.*</label>
                    <input type="text"name="cell"  defaultValue ={state.cellno} className="form-control" />
                </div> */}

                <div className="form-group">
                    <label>Email address</label>
                    <input type="email"name="email" defaultValue ={state.email} className="form-control" />
                </div>
                <button type="submit" className="btn btn-primary btn-block">Next ></button>
                
            </form>
        </div>
        
    );
};

export default CreateProfile;