import React, { useContext , useCallback } from "react";
import base_app from '../firebase';
import { AuthContext } from '../Auth';
import '../App.css';
import * as firebase from "firebase/app";
import "firebase/auth";


const LoginCellnumber = ({ history }) => {

	const setUpRecaptcha = () =>{
		window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
			'size': 'invisible',
			'callback': function(response) {
			}
		  });
	}

    const handleLogin = useCallback(async event => {
		event.preventDefault();
		setUpRecaptcha();
		base_app.auth().settings.appVerificationDisabledForTesting = true;
	
		const { cell } = event.target.elements; // convert to +27
		let number = '';

		if ( cell.value.startsWith("0") ){
			number = "+27" + cell.value.slice(1).replace(/\s/g, "");
		} else if (cell.value.startsWith("27") ){
			number = "+"+cell.value
		} else {
			number = cell.value
		}

		let appVerifier = window.recaptchaVerifier; 
		base_app
			.auth()
			.signInWithPhoneNumber(number, appVerifier)
			.then(function (confirmationResult){
				window.confirmationResult = confirmationResult;
				var code = window.prompt("Enter OTP");
				confirmationResult
					.confirm(code)
					.then(function (result) {
					// User signed in successfully.
					if (result.user.displayName === null){
						history.push("/signup");
					}else {
						history.push("/");
					}
					
				}).catch(function (error) {
				});
			
		}).catch(function(error) {
			if (error.code === 'auth/user-not-found'){
				alert('Could not find user. Please double-check and try again. If it\'s your first time using this site, please sign-up first.');
			
			} else if (error.code === 'auth/wrong-password'){
				alert('The password you have entered is invalid. Please double-check and try again.');
			
			}else {
				alert(error);
			}
			

            
        })
	}, [history]);

	


	const { currentUser } = useContext(AuthContext);
		if (currentUser) {
			history.push("/")
		}

	

        return (
            <div className="auth-wrapper">
				<form onSubmit={handleLogin}>
					<h3 style={header}>Welcome to Thenjiwe!</h3>
					<br></br>
					<h6 >Please enter your cell number to continue</h6>
					<div id="recaptcha-container"></div>

					<div className="form-group">
						<label></label>
						<input type="text" name="cell" className="form-control" placeholder="e.g. 0801234567"/>
					</div>

					<button type="submit" className="btn btn-primary btn-block">Next</button>
					</form>
					<div>
					
					{/* <p className="forgot-password text-right" style={{paddingTop:20}}>
						Don't have an account? <Link to='/signup' > Sign Up </Link>
					</p> */}
					{/* <button className="btn btn-link" onClick={forgotPassword} style={button}>
						Forgot password?
					</button> */}
			
					</div>
					
					
			</div>
            
        );
    };

export default LoginCellnumber;

const header = {
	color: '#33ACFF'
}