
export const CATEGORIES =
    [
        {
            id: 0,
            name:'Clothing',
            image: '/assets/images/clothes.jpg'
        },
        {
            id: 1,
            name:'Shoes',
            image: '/assets/images/shoes.png'
        },
        {
            id: 2,
            name:'Accessories',
            image: '/assets/images/store7.png'
        },
        {
            id: 3,
            name:'Hygiene Accessories',
            image: '/assets/images/option1.jpg'
        },
        {
            id: 4,
            name:'Travel',
            image: '/assets/images/travel.jpg'
        },
        {
            id: 5,
            name:'Home',
            image: '/assets/images/home.jpg'
        },

            
            
            
        
    ];
    