import React, { Component } from 'react';
import { Card, CardImg, CardTitle, CardBody, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { CATEGORIES } from '../shared/stores';


class CategoryCollection extends Component{

    constructor(props){
        super(props);

        this.state = {
            categories: CATEGORIES
      
        };

    }


render(){
       
    const menu = this.state.categories.map((category, key) => {
        return (
            <div  className="col-12 col-md-5 m-1 col-centered">
            <RenderSingleStore key={key} category={category} onClick={this.props.onClick}/>
            </div>
        );
    });

    return (
        <div className="container">
                <div className="row">
                <Breadcrumb>
                    <BreadcrumbItem><Link to="/home">Back to Home</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Buy For Me</BreadcrumbItem>
                </Breadcrumb> 
                </div>
                <div className="row row-centered">
                    {this.state.categories === null? <div style={loading}> Loading <br/> <br/><img src="assets/images/loading.gif"  height="70px" alt='Loading'/></div>: menu}
                </div>
        </div>
    );
    }
}

export default CategoryCollection;

const RenderSingleStore = ({key, category}) => {
    return (
        <Card style= {card}>
            <CardBody style = {{padding: '10px'}}>
            <Link to={`/categories/${category.id}`} key={key}>
                <CardImg width="80%" src={category.image} alt={category.name} />
                <CardTitle style={storeName}>{category.name}</CardTitle>
            </Link>
            </CardBody> 
        </Card>
    );
}


const loading = {
    textAlign: "center",
    padding: "100px 20px"
}

const storeName = {
    color: '#33ACFF',
    font: 'Roboto',
    float:'centre',
    padding: '25px 15px 0px 15px',
    margin: 0,
    textAlign: 'center'
}

const card = {
    boxShadow: 'rgb(199 201 201) 15px 10px 40px'
}


