import React from 'react';

function Footer() {
    return(
    <div className="footer">
        <div className="container">
            <p style={foot}>
                To get in touch with us, feel free to contact us at:
                <br/>
                <span style={{color:"#33ACFF"}} className="fa fa-envelope fa-sm"></span>&nbsp;<b style={{color:"#33ACFF"}}>info@thenjiwetrading.com</b>
                
            </p>
        </div>
    </div>
    )
}

export default Footer;
const foot = {
    bottom: "50px",
    color: "#000",
    paddingBottom:50,
    paddingLeft: 20,
    fontSize: 12,

}