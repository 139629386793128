import React, {  /*useCallback,*/useState, useEffect} from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import base_app from './../firebase'

const Address = ({history}) => {
    const [state, setState] = useState({ firebase: false, submission:''});

    useEffect(() => {
        if(base_app.auth().currentUser){
            if(state.firebase === false ){
                setState({firebase: true});
                // To get the name
                
                if ( base_app.auth().currentUser.displayName !== null){
                    base_app.database().ref('/users/' + base_app.auth().currentUser.uid).once('value').then(function(snapshot) {
                        if(typeof snapshot.val().notes !=='undefined'){
                            setState({name: base_app.auth().currentUser.displayName, email:base_app.auth().currentUser.email, cellno:base_app.auth().currentUser.phoneNumber, order: snapshot.val().order,  notes:snapshot.val().notes,
                                option:snapshot.val().option, ref:snapshot.val().ref, total: snapshot.val().total, delivery: snapshot.val().delivery});

                        }else{
                            setState({name: base_app.auth().currentUser.displayName, email:base_app.auth().currentUser.email, cellno:base_app.auth().currentUser.phoneNumber, order: snapshot.val().order,
                                option:snapshot.val().option, ref:snapshot.val().ref, total: snapshot.val().total, delivery: snapshot.val().delivery});
                        }
                      
                    });
                } else{
                    base_app.database().ref('/users/' + base_app.auth().currentUser.uid).once('value').then(function(snapshot) {
                        if(typeof snapshot.val().notes !=='undefined'){
                        setState({name: "X", email:base_app.auth().currentUser.email, cellno:base_app.auth().currentUser.phoneNumber, order: snapshot.val().order, notes:snapshot.val().notes,
                            option:snapshot.val().option, ref:snapshot.val().ref, total: snapshot.val().total, delivery: snapshot.val().delivery});

                        }else{
                            setState({name: "X", email:base_app.auth().currentUser.email, cellno:base_app.auth().currentUser.phoneNumber, order: snapshot.val().order,
                            option:snapshot.val().option, ref:snapshot.val().ref, total: snapshot.val().total, delivery: snapshot.val().delivery});
                        }
                    });

                }
             
            }
        
        }else {
            alert('Oops! something went wrong. Please re-add items to cart.');
            history.push("/home");
        }
    
    }, [state.firebase, history]);

    const sendEmail = (address, ref) => {
        
        if ( state.option === "option1") {
            console.log(state.notes)
          
            let orderr = [];
            let items = state.order;

        
            // add image here
            for (let i = 0;  i < items.length; i++){
                let obj = {}
                obj.productCode = items[i].productCode;
                obj.img = items[i].img;
                obj.city = items[i].city;
                obj.price = items[i].price;
                orderr.push(JSON.stringify(obj));
                
            }

            let obj = {"extra_notes": state.notes}
            

            orderr.push(JSON.stringify(obj));
            
            let message = {
                name: state.name, 
                email: state.email, 
                cellno: state.cellno, 
                address: address.address.toString(),
                order: orderr,
                delivery: state.delivery,
                ref: ref,
                option: state.option,
                total: state.total
            }   
    
            fetch('https://thenjiwe-trading.web.app/send',{
                method: "POST",
                body: JSON.stringify(message),
                headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                },
            }).then(
                (response) => (response.json())
                ).then((response)=>{
            if (response.status === 'success'){
                alert("Your order has been placed successfully. We've sent you an sms to confirm."); 
                
                    
                // move to new table
                base_app.database().ref('order_hist/' + base_app.auth().currentUser.uid +'/' +ref.ref).set({
                    option: state.option,
                    order: state.order,
                    status: 'pending',
                    date: new Date().toUTCString(),
                    total: state.total
            
                });

                base_app.database().ref('users/' + base_app.auth().currentUser.uid).update({order:'', ref:'', total: '', notes: ''});    
                history.push("/");
            }else if(response.status === 'fail'){
                alert("Message failed to send.")
            }
           })
        

        } else {


            let items = state.order;
            let orderr = [];
    
            orderr.push(JSON.stringify(items))
           
            let ref = {ref : state.ref}

            let message = {
                name: state.name, 
                email: state.email, 
                cellno: state.cellno, 
                delivery: "pick up",
                address: address.address.toString(),
                order: orderr,
                ref: ref,
                option: state.option
            }

            console.log(message);
    
            fetch('https://thenjiwe-trading.web.app/send',{
                method: "POST",
                body: JSON.stringify(message),
                headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                },
            }).then(
                (response) => (response.json())
                ).then((response)=>{
            if (response.status === 'success'){
                alert("Your order has been placed successfully. We've sent you an sms email to confirm."); 
                    
                // move to new table
                base_app.database().ref('order_hist/' + base_app.auth().currentUser.uid +'/' +ref.ref).set({
                    option: state.option,
                    order: state.order,
                    status: 'pending',
                    date: new Date().toUTCString()
            
                });

                base_app.database().ref('users/' + base_app.auth().currentUser.uid).update({order:'', ref:''});
                //delete item from carts/move to past orders
    
                history.push("/");
            }else if(response.status === 'fail'){
                alert("Message failed to send.")
            }
           })
        
       

           
    
        }

       


    }
   
    const submitOrder = ( async event => {
        event.preventDefault();
        const { street1, street2, suburb, city, province, postalcode } = event.target.elements;
        var address = {address:`${street1.value}\n${street2.value}\n${suburb.value}\n${city.value}\n${province.value}\n${postalcode.value}` }
        var date = new Date();


        try {
            var ref = { ref: state.name.charAt(0) + date.getTime() };
            await base_app.database().ref('users/' + base_app.auth().currentUser.uid).update({cellno: base_app.auth().currentUser.phoneNumber, email: base_app.auth().currentUser.email,  name:  base_app.auth().currentUser.displayName ,address:`${street1.value}\n${street2.value}\n${suburb.value}\n${city.value}\n${province.value}\n${postalcode.value}`, ref});
            setState({submission: "Your order is being submitted ..."});
            sendEmail(address, ref);

          
        }catch(error) {
            alert(error);
        }
      
    });

    const goBack = () =>{
        history.goBack();
    }


   return(
       <div className="Container">
            <div className="row">
            <Breadcrumb>

                <BreadcrumbItem><Link to="" onClick={goBack}>Back</Link></BreadcrumbItem>
            
                <BreadcrumbItem active>Delivery Address</BreadcrumbItem>
            </Breadcrumb>
            </div>
    <div>
        <h3>Address to Deliver to</h3>
    </div>
     <div  className ="auth-wrapper">
        <form id="contact-form" const onSubmit={submitOrder} method="POST">
        <div className="form-group">
            <label htmlFor="name">Street Address</label>
            <input type="text" name='street1' className="form-control" placeholder="e.g. 32 Gordan Street" required={true}/>
        </div>
        <div className="form-group">
            <label htmlFor="exampleInputEmail1">Complex/Building (optional) </label>
            <input type="text" name='street2' className="form-control" placeholder="e.g. 67 Rosemary Estate" />
        </div>
        <div className="form-group">
            <label htmlFor="message">Suburb</label>
            <input type="text" name='suburb'className="form-control" required={true}/>
        </div>
        <div className="form-group">
            <label htmlFor="message">City/Town</label>
            <input type="text" name='city' className="form-control" required={true}/>
        </div>
        <div className="form-group">
            <label htmlFor="message">Province</label>
            <input type="text" name='province' className="form-control" required={true}/>
        </div>
        <div className="form-group">
            <label htmlFor="message">Postal code</label>
            <input type="text" name='postalcode' className="form-control" required={true}/>
        </div>

        <button type="submit" style={Button}  className="btn btn-primary">Place Order&nbsp;&nbsp;<span className="fa fa-shopping-cart fa-md"></span></button>
        <p>{state.submission}</p>
        </form>
      </div>
      
      </div>
   );
  }
  
  
  export default Address;

  const Button = {
    backgroundColor: '#33ACFF',
    borderColor: '#33ACFF',
    color: '#fff',
    float: 'right',
    fontWeight: 400,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    padding: '.375rem .75rem',
    fontSize: '1rem'
  
}