import React, { Component } from 'react';
import CategoryCollection from './storesComponent';
import Catalogue from './CatalogueComponent';
import Header from './HeaderComponent';
import { Switch, Route, Redirect } from 'react-router-dom';

import { AuthProvider } from '../Auth';

import PrivateRoute from '../PrivateRoute';
import Cart from './CartComponent';
import Address from './AddressComponent';
import LoginCellnumber from './LoginCellnumberComponent';
import Profile from './ProfileComponent';
import CreateProfile from './CreateProfileComponent';
import PickUp from './PickUpComponent';
import Home from './HomeComponent';
import About from './AboutComponent';
import Footer from './FooterComponent';
import base_app from '../firebase';
import { CATEGORIES } from '../shared/stores';


class Main extends Component {

  constructor(props) {
    super(props);

    this.state = {
      categories: CATEGORIES,
      items: [],

    };

    let arr = []
    const db = base_app.firestore();
  

    db.collection("catalogueItems").get().then(querySnapshot => {
      querySnapshot.forEach(function(doc) {
        arr.push(({id: doc.id, ...doc.data()}))
      });

      this.setState({items: arr })
   });   
  
  }


  render() {

    const CategoriesWithId = ({match}) => {
      console.log(this.state.items)
      return(
          <Catalogue catalogue={this.state.categories.filter((i) => parseInt(i.id) === parseInt(match.params.catalogueId,10))[0]} 
            items={this.state.items.filter((item) => item.catalogueId === parseInt(match.params.catalogueId,10))} />
      );
    };

    return (
      <div>
        <AuthProvider>
          <Header/>
          <Switch>
            <PrivateRoute exact path='/'  component={Home} />
            <Route exact path='/home' component={Home} />
            <Route exact path='/signup' component={CreateProfile} />
            <Route exact path='/signin' component={LoginCellnumber} />
            <Route path='/categories/:catalogueId' component={CategoriesWithId} />
            <Route path='/categories' component={CategoryCollection} />
            <Route path='/address' component={Address} />
            <Route path='/cart' component={Cart} />
            <Route path='/profile' component={Profile} />
            <Route path='/pickup' component={PickUp} />
            <Route path='/about' component={About} />
            <Redirect to="/" />
          </Switch>
        </AuthProvider>
        <Footer/>

      </div>
      
    );
  }
}

export default Main;