import React, { Component } from 'react'; 

import {  Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import base_app from '../firebase';


class PickUp extends Component{
    constructor(props){
        super(props);

        this.state = {
            name: null,
            image :'',
            file : null, 
            fileURL : null,
            ref: null,
            paymentMethod: null,
            done: false,
            progress: 0,
            status: ""
        }
    }

    componentDidMount = () => {
        console.log("component did mount")
        if (base_app.auth().currentUser){
            console.log("current")

            base_app.database().ref('users/' + base_app.auth().currentUser.uid).once('value').then((snapshot) => {
                this.setState({name:base_app.auth().currentUser.displayName});
            });
    
        } else {
            this.props.history.push('/home')
        }
      
    }

    onUpload = () =>{

        let order = {
            option: "2",
            paymentMethod: this.state.paymentMethod,
            receipt: this.state.fileURL
        }
        
        base_app.database().ref('users/' + base_app.auth().currentUser.uid).update({order: order, option: "option2", ref: this.state.ref});


        // Create a reference to the file to delete
        // base_app.storage().ref().child(`temp/${this.state.file.name}`).delete();

     

    }

    fileUpload = (event) =>{
        this.setState({status: "uploading your image ..."})
        //create reference number
        let ref = {ref : this.state.name.charAt(0) + new Date().getTime() };
        this.setState({ref: ref.ref})

        // get file
        let file = event.target.files[0];

        // get user id
        let uid = base_app.auth().currentUser.uid;

        // storage reference
        let reference =  base_app.storage().ref(`${uid}/${ref.ref}`);

         // upload file
         let task = reference.put(file);

         let percentage = 0;
         // update progress bar
         task.on('state_changed', 
         (snapshot) => {
 
             percentage = (snapshot.bytesTransferred/ snapshot.totalBytes)*100;
             
             this.setState({progress: percentage});
 
         }, (err) => {
           alert("Failed to upload image. Please re-upload your image.");
 
         }, () => {
             this.setState({status: "Done!"})
             if (base_app.storage().ref(`${base_app.auth().currentUser.uid}/${ref.ref}`)){
                base_app.storage().ref(`${base_app.auth().currentUser.uid}/${ref.ref}`).getDownloadURL().then((url) =>{
                    this.setState({fileURL : url.toString()})   
        
                }, function error(res) {
                    console.log(res)
                    alert("Something went wrong. Please re-upload your image.");
          
                });
            }

    });
        

    }


    paymentMethod = (event) => {
        if(event.target.value === 'none'){
            alert('Please select a payment method');
            this.setState({paymentMethod : null})

        } else {
            this.setState({paymentMethod : event.target.value})
        }
       
    }

    render() {
        return (
            <div className="container" >
                 <div className="row">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/home">Back to Home</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Pick-Up and Deliver</BreadcrumbItem>
                    </Breadcrumb> 
                   
                </div>
                <div className="row" style={heading}>
                    <div  className="col-12 col-md-5 m-1" >
                        <h4>Step 1: </h4>
                        <h6> Please upload your receipt/proof of purchase</h6>
                        <hr/>
                
                 
                       
                        <progress value={this.state.progress} max="100" ></progress> 
                        {this.state.status}
                        <br/>

                        {/* {this.state.fileURL != null ? <img scr={this.state.fileURL} alt={"Receipt"}/> :  <div></div> } */}
                        <input style = {input} type="file" onChange={this.fileUpload.bind(this)}/>
                        <br/>
                        <br/>

                    </div>
                    {this.state.fileURL != null ?   
                        <div  className="col-12 col-md-5 m-1" style={step}>
                            <div>
                                <h4>Step 2: </h4>
                                <h6> Please select a payment method:</h6>
                                <hr/>
                            </div>
                            <form>
                                <select value={this.state.paymentMethod} style={select} onChange={this.paymentMethod.bind(this)}>
                                    <option value="none"></option>
                                    <option value="Capitec">Capitec</option>
                                    <option value="Shoprite">Shoprite</option>
                                    <option value="PEP">PEP</option>
                                    <option value="PayLater">Pay Later</option>
                                    <option value="PayOnline">Pay Online</option>

                                </select>
                            </form>
                        </div>

                    : <div></div>}
                  
                  </div>

                    <div className="row">


                    {this.state.paymentMethod != null ?   
                    <div  className="col-12 col-md-5 m-1">
                        <Link to='/address'>
                            <button style={storeName} className="btn btn-primary" onClick={this.onUpload.bind(this)}> 
                                Continue to Next Step&nbsp;&nbsp;<span className="fa fa-chevron-right fa-sm"></span>
                            </button>
                        </Link>
                      
                    </div> : 
                    <div></div>}
                  

                    </div>
            </div>
        );
    }
   
}

export default PickUp;

const storeName = {
    color: '#fff',
    backgroundColor: '#33ACFF',
    borderColor: '#33ACFF',
    alignItems:'right',
    margin: 20
}

const step = {
    alignItems: 'right',
}

const select = {
    minWidth: 200,
    float:'left'

}

const heading = {
    padding: '15px'

}
const input = {
    padding: '15px 15px 15px 0px'
}

