
import React, { Component } from 'react';
import { Card, CardText, CardBody, CardTitle, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import base_app from './../firebase';


class  Cart extends Component{
    constructor(props){
        super(props);

        this.state = {
            cart: null,
            total : '', 
            final: '',
            notes: '',
            deliveryOption: true,
            delivery: ''
    
        }
    }

    onNoteAdded = (event) =>{
		this.setState({notes: event.target.value})
	}

    componentDidMount = () =>{
  
        let total = 0;
        let final = 0;
        if(base_app.auth().currentUser){
            base_app.database().ref('/users/' + base_app.auth().currentUser.uid).once('value').then((snapshot) => {
                typeof snapshot.val().order != 'undefined'? this.setState({cart:snapshot.val().order}): this.setState({cart: 'empty'});
                
                let itemsLength = 0;
                let items = snapshot.val().order;
                typeof snapshot.val().order != 'undefined'? itemsLength = snapshot.val().order.length: itemsLength = 0
                
                console.log(snapshot.val().order);
                for (let i = 0;  i < items.length; i++){
                    if (items[i].catalogueId.toString() !=="5"){

                        total += parseInt(items[i].price)*parseInt(items[i].amount);
                    }else{
                        let arr = items[i].price.split(", R");
                        for (let j = 0; j < arr.length; j++){
                            total +=parseInt(arr[j]);
                        }
                       
                    }
                 
                }
                final = total

                this.setState({total: total});
                this.setState({final: final});
             
        }).catch((error) => {
            console.log(error);
        });;

        }

    }

    removeFromCart = (productCode) => {

        let items = this.state.cart;
        let total = this.state.total;
        let newtotal = 0;
        for (let i = 0; i < items.length ; i++){
            if(items[i].productCode.toString() === productCode.toString()){
                newtotal = parseInt(total - (items[i].price*items[i].amount))
                this.setState({total: newtotal})
                this.setState({final: newtotal + 100 +  newtotal*0.1.toFixed(2)})
                items.splice(i, 1);
                break;
            }
            
        
        
        }

        if(items.length === 1){
            this.setState({cart: 'empty'});

        }else {
            this.setState({cart: items});

        }
       

        
        console.log(items)
        base_app.database().ref('/users/' + base_app.auth().currentUser.uid).update({order: items})

    }

    submitCart = () => {
        // send product code + pricee
        if(base_app.auth().currentUser){
            base_app.database().ref('/users/' + base_app.auth().currentUser.uid).update({order: this.state.cart, notes: this.state.notes, option: "option1", total: this.state.final, delivery: this.state.delivery})
        
        }

    }

    onDeliveryOption = (event) =>{
        this.setState({deliveryOption: false})

        if ( event.target.value ==="aramax" ){
            this.setState({final: this.state.total + 100})
            this.setState({delivery: "aramex"})

        } else if ( event.target.value ==="pep"){
            this.setState({final: this.state.total + 60})
            this.setState({delivery: "PEP"})


        } else if ( event.target.value ==="intertown"){
            this.setState({final: this.state.total })
            this.setState({delivery: "Intertown Transport"})

        }
   
    }

    render(){
        console.log(this.state.cart)
    return (
        
        <div className="container">
            <div className="row">
            <Breadcrumb>

                <BreadcrumbItem><Link to="/categories">Back to All Categories</Link></BreadcrumbItem>
                <BreadcrumbItem active>Cart</BreadcrumbItem>
            </Breadcrumb>
            </div>
        
         {this.state.cart !== null ?  
            <div className="form-group">
            <Card >
                <CardBody>
                    <CardTitle>Your Cart items:</CardTitle>
                    <CardText>
                    <hr></hr>


                    {this.state.cart !== 'empty'? 
                    <div>
                         <ul className="list-unstyled">
                        { this.state.cart.map((item, key) =>
                            <div>
                                <li key = {key}> <img src={item.img} style={{width: '150px', height: '150px'}} alt='item'/>
                                <p style={price}>Price: R{item.price} * {item.amount} (Amount in bundle)</p>

                                <br/>
                                <button style={removeCart}  className="btn btn-primary" onClick={this.removeFromCart.bind(this, item.productCode)}>
                                    Remove item
                                    &nbsp;&nbsp;<span className="fa fa-remove fa-md"></span>
                                </button>


                                <br/>
                                <hr></hr>
                                </li>


                            </div>
                           
                        )}   
                        </ul>

                        <div className="form-group" style={{paddingTop: "15px"}}>
                            <label><b>Any additional notes you'd like to add about your order:</b></label>
                            <br></br>
                            <textarea name="note" cols="30" onChange={this.onNoteAdded.bind(this)}></textarea>
                        </div>

                    </div>
                   
                    : <div>There are no items in your cart</div>}
                
                    </CardText>
                </CardBody>
            </Card>

            {this.state.cart !== 'empty'? 

            <Card >
                <CardBody>
                    <CardTitle>Please Select A Delivery Option:</CardTitle>
                    <CardText>                   
                            <hr></hr>
                            <div style={fee} onChange={this.onDeliveryOption.bind(this)}>
                                    <br/>
                                    <input type="radio" value="aramax" name="delivery" style={{height: '0.85em'}}/> <b>Option 1: Aramax </b><br/>1-2 day delivery @ R100 delivery fee. 
                                    <br/>
                                    <br/>
                                    <input type="radio" value="pep" name="delivery" style={{height: '0.85em'}}/> <b>Option 2: PEP</b><br/>7-9 day delivery @ R60 delivery fee.
                                    <br/>
                                    <br/>
                                    <input type="radio" value="intertown" name="delivery" style={{height: '0.85em'}}/> <b>Option 3: Intertown Transport</b><br/>Delivery fee to be determined after checkout.
                                    <br/>

                              
                                <br/>
                            </div>
                            <br/>
                    </CardText>
                </CardBody>
            </Card>: <div></div>}

            {this.state.cart !== 'empty'? 

            <Card >
                <CardBody>
                    <CardTitle>Order Summary:</CardTitle>
                    <CardText>
                        <hr></hr>
                            Order Amount: R{this.state.total}<br/>
                            <b>Total Due (incl delivery): R{this.state.final}</b>

                        
                        <Link to='/address'> 
                            <button style={Button}  className="btn btn-primary" onClick={this.submitCart.bind(this)} disabled={this.state.deliveryOption}>  
                            Enter Delivery Address&nbsp;&nbsp;<span className="fa fa-home fa-md"></span>
                            </button>
                        </Link>
                        </CardText>

                </CardBody>
            </Card>: <div></div>}
                
            
            </div>
         
         : <div style={loading}> Loading your cart and calculating your total <br/> <br/><img src="assets/images/loading.gif"  height="70px" alt='Loading'/></div>}
        
       
        </div>
        
        
        );
    }

}


export default Cart;

const removeCart = {
    backgroundColor: '#6c757d',
    borderColor: '#6c757d',
    color: '#fff',
    float: 'left',
    fontWeight: 400,
    fontSize: 10

}

const price = {
    marginBottom: 0,
    fontSize: 11
}

const fee = {
    float: 'left',
    fontSize: 12
}


const Button = {
    backgroundColor: '#33ACFF',
    borderColor: '#33ACFF',
    color: '#fff',
    float: 'right',
    fontWeight: 400,
    fontSize: 12



}

const loading = {
    textAlign: "center",
    padding: "100px 20px"
}