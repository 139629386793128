import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/firestore";


const base_app = firebase.initializeApp({
    apiKey: "AIzaSyDEWJ3FkCAtWkWjP6WZv6yzszM_ck94RPI",
    authDomain: "thenjiwe-trading.firebaseapp.com",
    databaseURL: "https://thenjiwe-trading.firebaseio.com",
    projectId: "thenjiwe-trading",
    storageBucket: "thenjiwe-trading.appspot.com",
    messagingSenderId: "900048464544",
    appId: "1:900048464544:web:68f9d448781fedb74eb7c5",
    measurementId: "G-LNYHCFN129"
});

export const firestore = firebase.firestore();

export default base_app;