import React, { Component } from 'react'; 
import { Card, CardImg, CardTitle, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';;

class Home extends Component{
    
    constructor(props){
        super(props)

        this.state = {
            option1: "./assets/images/option1b.jpg",
            option2: "./assets/images/option2a.jpg"
        }
    }

    render(){
        return (
            <div className="container">
                <div className="row">
                    <div  className="col-12 col-md-5 m-1">
                        
                        <Card style={card}>
                            <CardBody style = {{padding: '7px'}}>
                            <Link to={'/categories'}>
                                <CardImg src={this.state.option1} alt="BuyForMe"/>
                                <CardTitle style={storeName}>Ready to shop now</CardTitle>
                            </Link>
                            </CardBody> 
                        </Card>
                    </div> 
                    <div  className="col-12 col-md-5 m-1">

                    <Card style={card}>
                            <CardBody style = {{padding: '7px'}}>
                            <Link to={'/pickup'} >
                                <CardImg src={this.state.option2} alt="PickUpAndDeliver" />
                                <CardTitle style={storeName}>Pick-up and Deliver</CardTitle>
                            </Link>
                            </CardBody> 
                        </Card>   
                        </div>        
                </div>
            </div>
        )
    }
}
   


export default Home;

const storeName = {
    color: '#33ACFF',
    font: 'Roboto',
    textAlign: "center",
    padding: '25px 15px 10px 15px',
    margin: 0
}

const card = {
    marginTop:'5vh',
    boxShadow: '15px 10px 40px #e0e0e0'
}


