
import React, { Component } from 'react';
import { Card, CardText, Breadcrumb, BreadcrumbItem, Button, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import base_app from './../firebase';

class  Catalogue extends Component{
    constructor(props){
        super(props);

        this.state = {
            userId: '',
            cart: [],
            cartlings: 0
        }
    }

    componentDidMount = () =>{
        console.log("component did mount")
        if(base_app.auth().currentUser){
                // To get the name

                base_app.database().ref('/users/' + base_app.auth().currentUser.uid).once('value').then((snapshot) => {
                    console.log(snapshot.val().order)
                    if (typeof snapshot.val().order !=='undefined'){
                        if (snapshot.val().order !==null){
                            
                        if(snapshot.val().order.length > 0){    
                            this.setState({cartlings: snapshot.val().order.length})
                            this.setState({cart: snapshot.val().order});
                        } 

                    }
                }
                  
                });

          
            }
    }

    addToCart = (item) =>{
        let temp = this.state.cart;
        let cart =  this.state.cartlings;

        if (typeof temp!== 'undefined'){
            temp.push(item);
   
        } else{
            temp = [];
            temp.push(item);
         
        }

        
        this.setState({ cart: temp});
        this.setState({ cartlings: parseInt(cart+1) });


    }

    checkOut = () =>{
        if(base_app.auth().currentUser){
            base_app.database().ref('/users/' + base_app.auth().currentUser.uid).update({order: this.state.cart})
        }
    }

    RenderStore(store){
    if (store != null)
        return(
          <div>
            <Card style = {{margin:0, padding: 0}}>
                {/* <CardImg top src={store.image} alt={store.name}/> */}
                <CardText><h4 style={Heading}>{store.name} - Catalogue</h4></CardText>
            </Card> 
            <br/>
          </div>  
        
       );
    else
        return(
            <div></div>
            );
    }

    RenderCatalogue(items) {
    if (items.length > 0){
       console.log()
        if ((items[0].catalogueId.toString() === "5") ){
            return (
                <>
                <div className="row">
                    {items.map((item, key) =>
                     <div className="col-12 col-md-5 m-1" style={addToCart}>
                        <RenderBaggage key={key} item={item}/>
                        <br/>
                        <Button onClick={this.addToCart.bind(this, item)}> Add to Cart</Button>
                    </div>
                    )}
                </div>      
                { this.state.cartlings > 0 ?
                    <div>
                        <Link to='/cart'> 
                            <button style={Buttn}  className="btn btn-primary" onClick={this.checkOut.bind(this)}>
                            Checkout {this.state.cartlings} item(s)&nbsp;&nbsp;<span className="fa fa-shopping-cart fa-md"></span>
                            </button>
                        </Link>  
                    </div>
                    : <div></div>}
                </>
            );
        } else if ((items[0].catalogueId.toString() === "3")){
            return (
                <>
                <div className="row">
                    {items.map((item, key) =>
                     <div className="col-12 col-md-5 m-1" style={addToCart}>
                        <RenderAccessories key={key} item={item}/>
                        <br/>
                        <Button onClick={this.addToCart.bind(this, item)}> Add to Cart</Button>
                    </div>
                    )}
                </div>      
                { this.state.cartlings > 0 ?
                    <div>
                        <Link to='/cart'> 
                            <button style={Buttn}  className="btn btn-primary" onClick={this.checkOut.bind(this)}>
                            Checkout {this.state.cartlings} item(s)&nbsp;&nbsp;<span className="fa fa-shopping-cart fa-md"></span>
                            </button>
                        </Link>  
                    </div>
                    : <div></div>}
                </>
                
            );
        }else if ((items[0].catalogueId.toString() === "6")){
            return (
                <>
                <div className="row">
                    {items.map((item, key) =>
                     <div className="col-12 col-md-10 m-1" style={addToCart}>
                        <RenderHomeware key={key} item={item}/>
                        <br/>
                        <Button onClick={this.addToCart.bind(this, item)}> Add to Cart</Button>
                    </div>
                    )}
                </div>      
                { this.state.cartlings > 0 ?
                    <div>
                        <Link to='/cart'> 
                            <button style={Buttn}  className="btn btn-primary" onClick={this.checkOut.bind(this)}>
                            Checkout {this.state.cartlings} item(s)&nbsp;&nbsp;<span className="fa fa-shopping-cart fa-md"></span>
                            </button>
                        </Link>  
                    </div>
                    : <div></div>}
                </>
                
            );
       
        } else {
            return (
                <>
                <div className="row">
                    {items.map((item, key) =>
                     <div className="col-12" style={addToCart}>
                        <RenderCatalogue key={key} item={item}/>
                        <br/>
                        {typeof item.description === 'undefined' ?   <Button onClick={this.addToCart.bind(this, item)}> Add to Cart</Button>:<div></div>}
                    </div>
                    )}
                </div>      
                { this.state.cartlings > 0 ?
                    <div>
                        <Link to='/cart'> 
                            <button style={Buttn}  className="btn btn-primary" onClick={this.checkOut.bind(this)}>
                            Checkout {this.state.cartlings} item(s)&nbsp;&nbsp;<span className="fa fa-shopping-cart fa-md"></span>
                            </button>
                        </Link>  
                    </div>
                    : <div></div>}
                </>
             
             );
            
        }
    } else{
        
        return (
          <div>
                <div style={{padding: '20px'}}>
                    <p>
                        There are no items to display in this category at the moment :(
                    </p>
                </div>
                { this.state.cartlings > 0 ?
                    <div >
                        
                        <Link to='/cart'> 
                            <button style={Buttn}  className="btn btn-primary" onClick={this.checkOut.bind(this)}>
                            Checkout {this.state.cartlings} item(s)&nbsp;&nbsp;<span className="fa fa-shopping-cart fa-md"></span>
                            </button>
                        </Link>  
                    </div>
                    
                    : <div></div>}
          </div>
        );  
    }  
     
    }

    render(){
    return (
        <div className="container">
            <div className="row">
                <br/>
                <Breadcrumb>
                    <BreadcrumbItem><Link to="/categories" onClick={this.checkOut.bind(this)}>Back to All Categories</Link></BreadcrumbItem>
                    <BreadcrumbItem active>{this.props.catalogue.name}</BreadcrumbItem>
                </Breadcrumb>            
            </div>

            {/* {this.RenderStore(this.props.catalogue)}  */}
            <div className="row">
                    {this.props.items === null? <div style={loading}> Loading <br/> <br/><img src="assets/images/loading.gif"  height="70px" alt='Loading'/></div>: this.RenderCatalogue(this.props.items)}

                </div>
            
        </div>
        );
    }
}

export default Catalogue;

const RenderHomeware = ({item}) => {
    return (
        <Card >
        <CardBody>
            <CardText>
                 <img  src={item.img} alt={item.productCode} width='180px' maxHeight='180px'/>
                    <br/>
                    <br/>
                    <div style= {details}>
                        <p ><b> Bulk Price: </b>R{item.price}.00</p>
                        <p><b> Bundle: </b>{item.amount}</p>
                        <p><b> Size(s): </b>{item.size}</p>
                        <p><b> Colours: </b>{item.colours}</p> 
                        <p style={{ color:"#33ACFF"}}><b> City: </b>{item.city}</p> 
                        <p style={{color:"#E60000", fontSize:20}}><b>{item.description}</b></p> 

                    </div>   
            </CardText>
        </CardBody>
    </Card>
    );
}

const RenderBaggage = ({item}) => {
    return (
        <Card >
        <CardBody>
            <CardText>
                    <img  src={item.img} alt={item.productCode} width='180px' maxHeight='180px'/>
                    <br/>
                    <br/>
                    <div style= {details}>
                        <p ><b> Bulk Price: </b>R{item.price}.00  respectively per size</p>
                        <p><b> Size(s): </b>{item.size}</p>
                        <p><b> Colours: </b>{item.colours}</p> 
                        <p style={{ color:"#33ACFF"}}><b> City: </b>{item.city}</p> 
                    </div>   
            </CardText>
        </CardBody>
    </Card>
    );
}

const RenderAccessories = ({item}) => {
    return (
        <Card >
        <CardBody>
            <CardText>
                    <img  src={item.img} alt={item.productCode} width='180px' maxHeight='180px'/>
                    <br/>
                    <br/>
                    <div style= {details}>
                    <p><b> Bulk Price: </b>R{item.price}.00</p>
                    <p><b> Bundle: </b>{item.amount}</p>
                    <p style={{ color:"#33ACFF"}}><b> City: </b>{item.city}</p> 
                    </div>   
            </CardText>
        </CardBody>
    </Card>
);
}

const RenderCatalogue = ({item}) => {
    return (
        <Card >
        <CardBody>
            <CardText>
                 <img  src={item.img} alt={item.productCode} width='180px' maxHeight='180px'/>
                    <br/>
                    <br/>
                    <div style= {details}>
                        <p ><b> Bulk Price: </b>R{item.price}.00</p>
                        <p><b> Bundle: </b>{item.amount}</p>
                        <p><b> Size(s): </b>{item.size}</p>
                        <p><b> Colours: </b>{item.colours}</p> 
                        <p style={{ color:"#33ACFF"}}><b> City: </b>{item.city}</p> 
                        <p style={{color:"#E60000", fontSize:20}}><b>{item.description}</b></p> 

                    </div>   
            </CardText>
        </CardBody>
    </Card>
    );
}

const Buttn = {
    backgroundColor: "#33ACFF",
    color: "#fff",
    border: "1px solid #33ACFF",
    textAlign: "center",
    padding: "20px",
    position: "fixed",
    left: "0",
    bottom: "0px",
    height: "60px",
    width: "100%"
}

const addToCart = {
    backgroundColor: '#fff',
    padding: "20px 20px 40px 20px"

}

const details = {
    fontSize: 12,
    overflowWrap: "break-word"
}

const Heading = {
    color: '#33ACFF',
    padding: 15,
    textAlign: 'center'
}

const loading = {
    textAlign: "center",
    padding: "100px 20px"
}




